<template>
    <div class="kmzp_headerbanner" style="background-color: #ffffff;background-image:url(/images/banner_bg3.jpg);background-position:center bottom;">
        <el-row :gutter="20" class="bannerbox">
            <el-col :span="16">
                <!--------------------------->
                <el-row :gutter="20" style="text-align: center;font-size: 16px;margin-top: 2px;">
                     <el-col :span="24" class="sz2023">本月新增岗位
                      <b v-html="jobscountstr"></b>
                      个</el-col>
                </el-row>
                <!--------------------------->
                <el-row :gutter="20">
                    <el-col :span="4"></el-col>
                    <el-col :span="16">
                        <div style="margin-top: 12px;margin-bottom: 12px;">
                            <el-input placeholder="搜索关键词" class="inputDeep"  v-model="searchkey" size="large" style="border: #e00303 0px solid;border-radius: 8px;">
                              <template #prepend>
                                <el-select input-style="font-size:30px;" size="large" v-model="searchtype" placeholder="Select" style="width: 98px;border-width: 0px;border-radius: 16px;">
                                  <el-option value="找工作">找工作</el-option>
                                  <el-option value="找人才">找人才</el-option>
                                </el-select>
                              </template>
                              <template #append>
                                <el-button color="#e00303" size="large" @click="searchsend" style="background-color: #e00303; color: #ffffff;border-top-left-radius: 0px;border-bottom-left-radius: 0px;">搜索</el-button>
                              </template>
                            </el-input>
                          </div>
                    </el-col>
                </el-row>
                <!--------------------------->
                <el-row :gutter="20">
                    <el-col :span="24" class="searchkey" v-if="searchtype=='找工作'">
                      <a href="/jobssearch?key=会计">会计</a>
                    <a href="/jobssearch?key=销售">销售</a>
                    <a href="/jobssearch?key=设计">设计</a>
                    <a href="/jobssearch?key=驾驶">驾驶</a>
                    <a href="/jobssearch?key=文员">文员</a>
                    <a href="/jobssearch?key=助理">助理</a>
                    <a href="/jobssearch?key=行政">行政</a>
                    <a href="/jobssearch?key=出纳">出纳</a>
                    <a href="/jobssearch?key=工程">工程</a>
                    <a href="/jobssearch?key=营销">营销</a>
                    <a href="/jobssearch?key=客服">客服</a>
                    <a href="/jobssearch?key=计算机">计算机</a>
                    </el-col>
                <el-col :span="24" class="searchkey" v-if="searchtype=='找人才'">
                    <a href="/resumes?key=会计">会计</a>
                    <a href="/resumes?key=销售">销售</a>
                    <a href="/resumes?key=设计">设计</a>
                    <a href="/resumes?key=行政">行政</a>
                    <a href="/resumes?key=出纳">出纳</a>
                    <a href="/resumes?key=客服">客服</a>
                    <a href="/resumes?key=计算机">计算机</a>
                    <a href="/resumes?key=驾驶员">驾驶员</a>
                </el-col>
                </el-row>
                <!--------------------------->
            </el-col>
            <el-col :span="8">
              <!---login-in------------------------>
              <el-row  :gutter="20" v-if="userinfo.usersid>0" class="homemenubox">
                <el-col :span="24" class="homemenu" v-if="userinfo.userstype==1">
                    欢迎您：{{ userinfo.usersphone }}
                    <el-row  :gutter="20" style="width: 90%;margin-left: 5%;">
                      <el-col :span="8"><a href="/resume" class="homemenuitem"><el-icon><User /></el-icon>简历管理</a></el-col>
                      <el-col :span="8"><a href="/apply" class="homemenuitem"><el-icon><Position /></el-icon>应聘记录</a></el-col>
                      <el-col :span="8"><a href="/invite" class="homemenuitem"><el-icon><CoffeeCup /></el-icon>面试邀请</a></el-col>
                      <el-col :span="8"><a href="/collection" class="homemenuitem"><el-icon><Collection /></el-icon>收藏管理</a></el-col>
                      <el-col :span="8"><a href="/reading" class="homemenuitem"><el-icon><View /></el-icon>查看记录</a></el-col>
                      <el-col :span="8"><a href="/readingme" class="homemenuitem"><el-icon><Star /></el-icon>谁看过我</a></el-col>
                      <el-col :span="8"><a href="/chats" class="homemenuitem" style="color: #e00303;font-size: 14px;"><el-icon><img src="/images/goutong-2.png" style="width:16px;height;16px;" /></el-icon>在线沟通</a></el-col>
                      <el-col :span="8"><a @click="loginout" class="homemenuitem2" style="cursor: pointer;color:#e00303;"><el-icon><SwitchButton /></el-icon>退出登陆</a></el-col>
                    </el-row>
                </el-col>
                <el-col :span="24" class="homemenu" v-if="userinfo.userstype==2">
                    欢迎您：{{ userinfo.usersphone }}
                    <el-row  :gutter="20" style="width: 90%;margin-left: 5%;">
                      <el-col :span="8"><a href="/companys/company" class="homemenuitem2"><el-icon><OfficeBuilding /></el-icon>企业信息</a></el-col>
                      <el-col :span="8"><a href="/companys/jobs" class="homemenuitem2"><el-icon><CoffeeCup /></el-icon>职位管理</a></el-col>
                      <el-col :span="8"><a href="/companys/jobse?edit=true" style="color: #e00303;" class="homemenuitem2"><el-icon><CirclePlus /></el-icon>发布职位</a></el-col>
                      <el-col :span="8"><a href="/companys/apply" class="homemenuitem2"><el-icon><Lollipop /></el-icon>收到简历</a></el-col>
                      <el-col :span="8"><a href="/companys/invite" class="homemenuitem2"><el-icon><Position /></el-icon>邀请记录</a></el-col>
                      <el-col :span="8"><a href="/companys/collection" class="homemenuitem2"><el-icon><Collection /></el-icon>收藏简历</a></el-col>
                      <el-col :span="8"><a href="/companys/reading" class="homemenuitem2"><el-icon><View /></el-icon>查看记录</a></el-col>
                      <el-col :span="8"><a href="/companys/readingme" class="homemenuitem2"><el-icon><Star /></el-icon>谁看过我</a></el-col>
                      <el-col :span="8"><a href="/chats" class="homemenuitem2" style="color:#e00303;font-size: 14px;"><el-icon><img src="/images/goutong-2.png" style="width:16px;height;16px;" /></el-icon>在线沟通</a></el-col>
                      <el-col :span="8"><a @click="loginout" class="homemenuitem2" style="cursor: pointer;color:#e00303;"><el-icon><SwitchButton /></el-icon>退出登陆</a></el-col>
                    </el-row>
                </el-col>
              </el-row>
              <!---login-in------------------------>
              <!---login------------------------>
              <el-row v-if="userinfo.usersid==0" :gutter="20" class="homemenubox">
                <el-col :span="18">
                  <el-row>
                    <el-col :span="24">
                      <!---login------------------------>
                      <el-form style="padding-left:6px;padding-right:2px;margin-top: 30px;" size="small" ref="users" label-position="right"  :model="users" :rules="rules">
                        <el-form-item label="" prop="usersphone">
                          <el-input type="text" v-model="users.usersphone" placeholder="请输入用户名" />
                        </el-form-item>
                        <el-form-item label="" prop="userspassword">
                          <el-input type="password" v-model="users.userspassword" placeholder="请输入密码" />
                        </el-form-item>
                        <el-form-item>
                          <el-button color="#dc3023" style="width:100%;" :loading="loading" @click="sendlogin('users')">登陆</el-button>
                        </el-form-item>
                      </el-form>
                      <!---login------------------------>
                    </el-col>
                    <el-col :span="24" style="margin-bottom: 8px;margin-top:-10px; display: none;">
                       <el-row>
                        <el-col :span="24" style="font-size: 13px;text-align: center;">———— 合作登陆 ————</el-col>
                       </el-row>
                       <el-row style="text-align: center;">
                        <el-col :span="2"></el-col>
                        <el-col :span="10"><img src="http://www.kmzp.com/skin/skin2017/assets/images/qq_login.png" /></el-col>
                        <el-col :span="10"><img src="http://www.kmzp.com/skin/skin2017/assets/images/weixin.png" /></el-col>
                        <el-col :span="1"></el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                </el-col>
                <el-col :span="6">
                  <el-row :gutter="20" style="line-height: 28px;margin-top: -5px;">
                    <el-col :span="24">

    <span @mouseover="showTooltip" @mouseout="hideTooltip">
      <el-button color="#f0f0f0" style="padding-right: 0px;font-size: 9px;font-weight: normal;" type="default" size="small">扫码登陆<img src="/images/erweima.png" @click="logintype('code')" onmouseover="this.src='/images/erweima2.png'" onmouseout="this.src='/images/erweima.png';" style="width:25px;" /></el-button>
    </span>
    <!-- Tooltip 组件 -->
    <div v-if="isTooltipVisible" style="position: absolute;padding: 20px; background-color: #ffffff; margin-left: -300px;width: 255px;margin-top: -25px;border-radius: 12px;height: 138px;text-align: center;line-height: 20px;">
      <img :src="loginpiccode" alt="扫码登陆" style="width: 128px;margin-top: -16px;"><br />
      <div v-if="logininfo.length>0" style="background-color: rgba(0, 0, 0, 0.9);padding: 8px;position: absolute;color: #ffffff;margin-top: -80px;margin-left: 77px;font-size: 14px;">{{ logininfo }}</div>
      <span style="font-size: 12px;color: #888888;">使用昆明招聘网App扫码登陆</span>
    </div>
                    </el-col>
                    <el-col :span="24"><a href="/reg?rt=company"><el-button color="#f0f0f0" type="default" size="small">企业注册</el-button></a></el-col>
                    <el-col :span="24"><a href="/reg"><el-button color="#f0f0f0" size="small">个人注册</el-button></a></el-col>
                    <el-col :span="24"><el-button color="#f0f0f0" style="color: #e00303;" @click="getpassworddiao=true" size="small" >忘记密码</el-button></el-col>
                    <el-col :span="24" style="padding-top: 2px;text-align: center;"><img src="/images/ma2020.png" style="width: 52px;" /></el-col>
                  </el-row>
                </el-col>
              </el-row>
              <!---login------------------------>
            </el-col>
        </el-row>
    </div>

    <!--content---->
    <div class="kmzp_content">
      <el-row :gutter="10" class="contentbox">
        <!--content-left--------------------------------------->
        <el-col :span="6">
          <div class="contentboxleft">
            <el-row :gutter="6">
            <el-col :span="12" style="padding-top: 8px;" v-for="item in categorylistroot1" :key="item.categoryid">
              <!-- <el-card shadow="hover" :body-style="{ padding: '0px',height:'25px' }"> -->
                <div class="categorylista">
                  <el-link :underline="false" :href="'jobssearch?cid='+item.categoryid" target="_blank" class="title1">{{item.categoryname}}</el-link>
                </div>
              <!-- </el-card> -->
            </el-col>
        </el-row>
          </div>
          <div class="contentboxleft">
            <h3 class="left_title1"><el-icon><Menu /></el-icon>推荐人才</h3>
            <div class="list_index1" style="text-align: left;">
              <el-row gutter="12" v-for="item in resumelist" :key="item.resumeid" style="margin-top: 10px;">
                  <el-col :span="24" class="resumeinfo">
                  <div style="text-align: left;" class="sltext">
                    <a :href="'/resumeitem?id='+item.resumeusersid" target="_blank">
                      <span style="font-size: 14px;">{{item.resumename}}</span>
                    </a>
                    <span style="margin-left: 16px;">{{ getAge(item.resumebirthday) }}<el-divider direction="vertical" /></span>
                      <span v-if="item.resumeworkage!=''">{{ item.resumeworkage }}<el-divider direction="vertical" /></span>
                      <span v-if="String(item.resumespecialty).length>0&&item.resumespecialty!=undefined">{{String(item.resumespecialty)}}//{{item.resumespecialty}}//<el-divider direction="vertical" /></span>
                      <span v-if="item.resumeschoolage!=''">{{ item.resumeschoolage }}<el-divider direction="vertical" /></span>
                  </div>
                  <el-divider style="margin-top: 2px;margin-bottom: 2px;border-color: #f0f0f0;" />
                  </el-col>
              </el-row>
              <div style="text-align:right;"><a href="/resumessearch" target="_blank" style="font-size: 14px;line-height: 32px;color: #9e9e9e;">更多人才>>>></a></div>
            </div>
          </div>
        </el-col>
        <!--content-left--------------------------------------->
         <!--content-right--------------------------------------->
        <el-col :span="18">
          <!--轮播、公告----------------------->
          <el-row :gutter="10">
            <el-col :span="16">
              <div style="padding: 0px;border-radius: 5px;height: 218px;">
                <!-----ad------------------------>
                 <el-carousel indicator-position="none" :loop="true" :autoplay="true" :interval="2000" height="218px" style="border-radius: 5px;">
                  <el-carousel-item v-for="(iimg, index) in imgs" :key="index" style="border-radius: 5px;">
                    <el-image style="width:100%;border-radius: 5px;" :src="iimg" :fit="fit" @click="adclick(index)">
                    </el-image>
                  </el-carousel-item>
                </el-carousel>
                <!-----ad------------------------>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="contentboxright" style="height: 193px;padding: 12px;">
                <!-- <h3 style=""> -->
                  <h3 class="left_title1" style="margin-top: 6px;margin-bottom: 6px;"><el-icon><CollectionTag /></el-icon>
                  公告</h3>
                  <div class="gglist">
                    <div v-for="item in gglist" :key="item.articleid">
                      <a :href="'/articles?id='+item.articleid" target="_blank" v-if="item.articletitle.length<18" style="line-height: 22px;">{{ item.articletitle }}</a>
                      <a :href="'/articles?id='+item.articleid" target="_blank" v-else style="line-height: 22px;">{{ item.articletitle.substring(0,18) }}</a>
                   </div>
                   <div style="font-weight: bold;line-height: 28px; color: red;">
                    服务监督热线：0871-67165818
                   </div>
                  </div>
              </div>
            </el-col>
          </el-row>
          <!--轮播、公告----------------------->
          <!--全通----------------------->
          <el-row :gutter="0" style="padding:1px;border-radius: 6px;background-color: #ffffff;">
            <el-col :span="24" v-for="item in qtlist" :key="item.promotionuserid">
              <a :href="'/companyitem?id='+item.promotionuserid" :title="item.promotionuser" target="_blank"><el-image :src="baseurl+item.promotionpic" :alt="item.promotionuser" :title="item.promotionuser" :fit="fill"></el-image></a>
            </el-col>
          </el-row>
          <!--半通----------------------->
          <el-row  style="margin-top: 8px;padding:1px;border-radius: 5px;background-color: #ffffff;">
            <el-col :span="12" class="bt_ad" v-for="item in btlist" :key="item.promotionuserid">
              <a :href="'/companyitem?id='+item.promotionuserid" :title="item.promotionuser" target="_blank"><el-image :src="baseurl+item.promotionpic" :alt="item.promotionuser" :title="item.promotionuser" :fit="fill"></el-image></a>
            </el-col>
          </el-row>
          <!--半通----------------------->

          <!--品牌----------------------->
          <h3 class="left_title1" style="margin-top:32px;margin-bottom: 12px;"><el-icon><Menu /></el-icon>品牌企业</h3>
          <el-row style="padding:1px;border-radius: 5px;background-color: #ffffff;">
            <el-col :span="6" class="tg1_ad" v-for="item in pplist" :key="item.promotionuserid">
              <el-popover placement="right" :width="198">
              <template #reference>
                <a :href="'/companyitem?id='+item.promotionuserid" :title="item.promotionuser" target="_blank" @mouseenter="getjoblist(item.promotionuserid)"><el-image :src="baseurl+item.promotionpic" :alt="item.promotionuser" :title="item.promotionuser" :fit="fill"></el-image></a>
              </template>
              <div v-html="getjoblists(item.promotionuserid)" style="line-height: 26px;">
              </div>
            </el-popover>
            </el-col>
          </el-row>
          <!--品牌----------------------->

          <!--紧急----------------------->
          <h3 class="left_title1" style="margin-top:32px;margin-bottom: 12px;"><el-icon><Menu /></el-icon>紧急招聘</h3>
          <el-row :gutter="0" style="padding: 1px;border-radius: 5px;background-color: #ffffff;">
            <el-col :span="4" class="tg2_ad" v-for="item in jjlist" :key="item.promotionuserid">
               <el-popover placement="right" :width="198" style="margin-bottom: 12px;">
              <template #reference>
                <a :href="'/companyitem?id='+item.promotionuserid" :title="item.promotionuser" target="_blank" @mouseenter="getjoblist(item.promotionuserid)"><el-image :src="baseurl+item.promotionpic" :alt="item.promotionuser" :title="item.promotionuser" :fit="fill"></el-image></a>
              </template>
              <div v-html="getjoblists(item.promotionuserid)" style="line-height: 26px;">
              </div>
            </el-popover>
            </el-col>
          </el-row>
          <!--紧急----------------------->

          <!--热点招聘----------------------->
          <h3 class="left_title1" style="margin-top:32px;margin-bottom: 12px;"><el-icon><Menu /></el-icon>热点招聘</h3>
          <el-row :gutter="0" style="padding: 1px;border-radius: 5px;background-color: #ffffff;">
            <el-col :span="8" v-for="item in hotjoblist" :key="item.jobid" style="margin-top: 6px;margin-bottom: 6px;">
              <div style="padding-left: 20px;padding-right: 20px;padding-top: 1px;padding-bottom: 1px;">
                <a :href="'/jobitem?id='+item.jobid" target="_blank" class="hotjoblistitem" style="height:42px;margin-top: 0px;">
                  <el-row :gutter="0">
                    <el-col :span="24" style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{{item.jobname}}</el-col>
                  </el-row>
                </a>
                <a :href="'/companyitem?id='+item.jobusersid" target="_blank" class="hotjoblistitemc" style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">
                  {{item.jobcompanyname.length>15?item.jobcompanyname.substring(0,15)+'..':item.jobcompanyname}}
                  <span style="color:#e00303;margin-left:6px;">诚聘</span>
                </a>
                <el-divider style="margin-top: 2px;margin-bottom: 2px;border-color: #f0f0f0;"/>
                </div>
            </el-col>
          </el-row>
        </el-col>
        <!--content-right--------------------------------------->
      </el-row>
    </div>
    <!--content2 友情链接---->
    <div class="kmzp_contentlink" style="padding-left: 16px;padding-right: 16px;">
      <el-row :gutter="20" class="contentbox">
        <el-col :span="24">
          <div class="contentboxleft" style="padding-bottom: 12px;">
            <h4 style="margin-top: 8px;margin-bottom: 6px;padding: 8px;">友情链接</h4>
            <el-row :gutter="60" style="line-height: 26px;">
              <el-col :span="4" v-for="item in linklist" :key="item.linkid">
                <a class="linkitem" :href="item.linkcontent" :title="item.linktitle" target="_blank">{{ item.linktitle }}</a>
              </el-col>
            </el-row>
          </div>
        </el-col>
    </el-row>
    </div>
<div id="ydinfo" v-if="showbox==true" style="background: #000;background-color:rgba(0,0,0,0.8);color:#fff;width: 100%;height:60px;position:fixed;bottom: 0px;left: 0px; z-index:1000;">
<p style="color: #FFFFFF;text-align:center;font-size:25px; width:100%; line-height:52px;padding: 0px; margin-top: 8px;">
<span style="margin-right: 0px;float: right;margin-right: 20px;margin-bottom: 12px;color: red;" @click="hidebox">X</span>
没有找到适合的工作？
<el-button style="margin-top: -6;" type="primary" tag="a" href="/reg" target="_blank" rel="noopener noreferrer" color="red">
  一分钟留下信息
  </el-button>
我们会将你推荐给优秀企业！坐等工作来找你！
</p>
</div>
<!-- 密码找回 ------------------------------------- -->
<el-dialog v-model="getpassworddiao" title="找回密码" width="418px">
    <el-form style="padding-right: 20px;">
      <el-form-item label="输入手机号码" label-width="108px">
        <el-input v-model="getpasswordphone" type="text"/>
      </el-form-item>
      <el-form-item label="" label-width="108px">
        <el-button color="#333333" @click="getpassword">提交找回密码</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { getjsons, gethomejsons, login, getuserprofile, resumesearchs, getsaoma, hotjobs, jobsc, jobscountget, passwordget } from '@/api/user'

import token from '@/utils/token'
import { ElMessageBox } from 'element-plus'
import store from '@/store/index'
import timehelp from '@/utils/timehelp'

import '@/styles/style_me.css'
export default {
  name: 'homeIndex',
  components: {
  },
  props: {},
  data () {
    return {
      getpasswordphone: '',
      getpassworddiao: false,
      isTooltipVisible: false,
      // tooltipImageSrc: 'http://www.kmzp.com/skin/skin2017/assets/images/ma2020.png',
      loginid: '',
      loginpiccode: '',
      logininfo: '',
      baseurl: store.state.baseUrl,
      searchtype: '找工作',
      searchkey: '',
      fit: 'cover',
      imgs: [],
      adurl: [],
      categorylist1: [],
      categorylistroot1: [],
      gglist: [],
      linklist: [],
      adinfo: [],
      gjjoblist: [],
      hotjoblist: [],
      resumelist: [],
      qtlist: [],
      btlist: [],
      pplist: [],
      jjlist: [],
      users: { usersid: 0 },
      userinfo: { usersid: 0 },
      showbox: true,
      adjoblist: [],
      rules: {
        usersphone: [
          { required: true, message: '请输入用户名', trigger: 'blur' }
        ],
        userspassword: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, max: 12, message: '请输入6-12位密码', trigger: 'blur' }
        ]
      },
      jobscount: 2018,
      jobscountstr: ''
    }
  },
  computed: {},
  watch: {},
  created () {
    this.getcode()
    this.$parent.$parent.hendersetshow(true)
    this.$parent.$parent.footsetshow(true)
    this.getcategorys()
    this.getuserss()
    this.getjobscount()
  },
  mounted () {},
  methods: {
    getcategorys () {
      this.categorylist = []
      this.categorylistroot1 = []
      getjsons('category', 1).then(res => {
        if (res.data != null) {
          for (let i = 0; i < res.data.length; i++) {
            this.categorylist1.push(res.data[i])
            if (res.data[i].categoryroot === 0) {
              this.categorylistroot1.push(res.data[i])
            }
          }
        }
      })
      // 公告 t=article&at=1&pagesize=2
      gethomejsons('/homes/jsons2?t=article&at=1&pagesize=6').then(res => {
        this.gglist = []
        if (res.data != null) {
          for (let i = 0; i < res.data.length; i++) {
            this.gglist.push(res.data[i])
          }
        }
      })
      // 链接 /homes/jsons?t=link
      gethomejsons('/homes/jsons2?t=link').then(res => {
        this.linklist = []
        if (res.data != null) {
          for (let i = 0; i < res.data.length; i++) {
            this.linklist.push(res.data[i])
          }
        }
      })
      // 广告 /homes/jsons?t=ad
      gethomejsons('/homes/jsons2?t=ad&id=3').then(res => {
        if (res.data != null) {
          this.adinfo = res.data
          if (res.data.Adtitle != null && res.data.Adstatus > 0) {
            for (let i = 0; i < res.data.Adcontent.split('¤').length; i++) {
              const aditem = res.data.Adcontent.split('¤')[i]
              if (aditem.split('|').length === 5) {
                this.imgs.push(aditem.split('|')[0])
                if (aditem.split('|')[2] !== '0') {
                  this.adurl.push('/companyitem?id=' + aditem.split('|')[2].replace(' ', ''))
                } else {
                  this.adurl.push(aditem.split('|')[3])
                }
              }
            }
          }
        }
      })
      // 高级岗位
      // this.gjjoblist = []
      // jobsearchs(1, 18, 'GJ', '', '', '', '').then(res => {
      //   if (res.data.resultbody != null) {
      //     this.gjjoblist = res.data.resultbody
      //   }
      // })
      // 热点招聘
      this.hotjoblist = []
      hotjobs().then(res => {
        if (res.data.resultbody != null) {
          this.hotjoblist = res.data.resultbody.resultlist
        }
      })
      // 最新人才
      this.resumelist = []
      resumesearchs(1, 20, '', '').then(res => {
        if (res.data.resultbody != null) {
          for (let i = 0; i < res.data.resultbody.length; i++) {
            const iteminfo = res.data.resultbody[i]
            this.resumelist.push(iteminfo)
          }
        }
      })
      // 紧急/logo招聘//
      gethomejsons('homes/jsons2?t=tglist&tt=2').then(res => {
        this.jjlist = []
        if (res.data != null) {
          this.jjlist = res.data
        }
      })
      // 品牌//
      gethomejsons('homes/jsons2?t=tglist&tt=5').then(res => {
        this.pplist = []
        if (res.data != null) {
          this.pplist = res.data
        }
      })
      // 全通 //
      gethomejsons('homes/jsons2?t=tglist&tt=6').then(res => {
        this.qtlist = []
        if (res.data != null) {
          this.qtlist = res.data
        }
      })
      // 半通 //
      gethomejsons('homes/jsons2?t=tglist&tt=7').then(res => {
        this.btlist = []
        if (res.data != null) {
          this.btlist = res.data
        }
      })
    },
    sendlogin (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true
          if (this.checkpassword === true) {
            token.setCookie('name', this.users.usersphone)
            token.setCookie('password', this.users.userspassword)
          } else {
            token.setCookie('name', '')
            token.setCookie('password', '')
          }
          login(this.users).then(res => {
            if (res.data.status === 200) {
              token.settoken('utoken', res.data.resultbody.Token)
              this.$message({
                message: '登陆成功！',
                type: 'success'
              })
              this.loading = false
              this.getusers()
            } else {
              this.loading = false
              ElMessageBox.alert(res.data.message, '', {
                confirmButtonText: 'OK',
                icon: 'WarningFilled'
              })
            }
          })
        } else {
          return false
        }
      })
    },
    getusers () {
      getuserprofile().then(res => {
        if (res.data.resultbody != null) {
          this.userinfo = res.data.resultbody
          if (this.userinfo.usersstatus > 0) {
            alert('您好！帐号锁定了！')
            token.settoken('utoken', '')
            this.$router.push('/login')
          } else {
            if (this.userinfo.userstype === 1) {
              top.location.href = '/resumes/home'
              // this.$router.push('/resumes/home')
            } else {
              // this.$router.push('/companys/home')
              top.location.href = '/companys/home'
            }
          }
        } else {
          token.settoken('utoken', '')
        }
      })
    },
    getuserss () {
      getuserprofile().then(res => {
        if (res.data.resultbody != null) {
          this.userinfo = res.data.resultbody
        }
      })
    },
    loginout () {
      token.settoken('utoken', '')
      top.location.href = '/login'
    },
    getAge (vv) {
      return timehelp.getAge(vv)
    },
    isJsonString (str) {
      try {
        JSON.parse(str)
      } catch (e) {
        return false
      }
      return true
    },
    // 扫码登陆
    showTooltip () {
      this.isTooltipVisible = true
    },
    hideTooltip () {
      this.isTooltipVisible = false
    },
    // 获取二维码
    getcode () {
      const currentDate = new Date()
      this.loginid = currentDate.getFullYear() + '' + currentDate.getMonth() + '' + currentDate.getDate() + '' + currentDate.getHours() + '' + currentDate.getMinutes() + '' + currentDate.getSeconds()
      this.loginpiccode = store.state.baseUrl + 'user/saoma?logint=0&loginid=' + this.loginid
      // alert(this.loginpiccode)
      this.timer = setInterval(this.getcodeinfo, 2000)
    },
    // 定时获取登陆状态
    getcodeinfo () {
      getsaoma(this.loginid).then(res => {
        const logininfos = res.data
        if (logininfos.split('|').length === 2) {
          if (logininfos.split('|')[0] === '1') {
            this.logininfo = '请稍等已扫码'
          }
          if (logininfos.split('|')[1].length > 50) {
            this.logincontent = logininfos.split('|')[1]
            token.settoken('utoken', this.logincontent)
            this.$message({
              message: '登陆成功！',
              type: 'success'
            })
            this.loading = false
            clearInterval(this.timer)
            this.getusers()
          }
        }
      })
    },
    beforeDestroy () {
      // 在组件销毁之前清除定时器
      clearInterval(this.timer)
    },
    hidebox () {
      this.showbox = false
    },
    getjoblist (id) {
      let cjoblist = []
      const itemWith = this.adjoblist.find(item => item.id === id)
      // alert(itemWith)
      if (itemWith === undefined) {
        jobsc(1, 1, 5, '', '', id).then(res => {
          if (res.data.resultbody.resultlist != null) {
            cjoblist = res.data.resultbody.resultlist
            const adjobitem = { id: id, list: cjoblist }
            this.adjoblist.push(adjobitem)
          }
        })
      }
    },
    getjoblists (id) {
      const itemWith = this.adjoblist.find(item => item.id === id)
      let listjob = ''
      if (itemWith !== undefined) {
        // alert(itemWith.list)
        for (let i = 0; i < itemWith.list.length; i++) {
          listjob += '<a href=\'/jobitem?id=' + itemWith.list[i].jobid + '\' target=\'_blank\'>' + itemWith.list[i].jobname + '</a><br />'
        }
      }
      return listjob
    },
    getjobscount () {
      jobscountget().then(res => {
        if (res.data.resultbody != null) {
          this.jobscount = res.data.resultbody * 3
        }
        const numberString = this.jobscount.toString() // 将数字转换为字符串
        // 将每个字符存入数组
        for (let i = 0; i < numberString.length; i++) {
          this.jobscountstr += '<span>' + numberString.charAt(i) + '</span>'
        }
      })
    },
    // 搜索处理
    searchsend () {
      if (this.searchkey.length === 0) {
        alert('请输入搜索关键词！')
      } else {
        if (this.searchtype === '找工作') {
          top.location.href = '/jobssearch?key=' + this.searchkey
        } else {
          top.location.href = '/resumessearch?key=' + this.searchkey
        }
      }
    },
    adclick (ii) {
      // alert(ii)
      window.open(this.adurl[ii], '_blank')
    },
    getpassword () {
      if (this.getpasswordphone.length === 11) {
        passwordget(this.getpasswordphone).then(res => {
          if (res.data.states === 200) {
            alert('找回密码操作成功！请注意查收密码短信！')
          } else {
            alert(res.data.message)
          }
          this.getpasswordphone = ''
          this.getpassworddiao = false
        })
      } else {
        alert('请检查手机号码输入是否正确！')
      }
    }
  }
}
</script>
