<template>
<el-header class="hender_a">
      <div class="henderbox">
        <el-row>
              <el-col :span="6"><img src="/images/logoa.png" style="height:45px; margin-left: 20px;" /></el-col>
              <el-col :span="16">
                <a href="/">首页</a>
                <a href="/jobs">找工作</a>
                <a href="/resumessearch">找人才</a>
                <a href="/senior">高级岗位</a>
              </el-col>
         </el-row>
      </div>
</el-header>
<div v-loading="loadingmain" :style="'height:'+ boxheight+'px;'"></div>
<div style="width: 100%;padding-top:0px;padding-bottom: 100px;min-width:9px;margin-top: 0px;" v-if="jobs.jobid>0">
    <el-row :gutter="20" style="width:100%; max-width: 1188px;margin-left: auto; margin-right: auto; background-color: #ffffff;line-height: 30px; font-size: 14px;">
      <el-affix :offset="298" style="margin-left:1188px; height:1px;">
        <el-button  color="#dc3023" size="small" style="padding-right:20px;font-size:12px; width:52px;height:52px;font-size: 14px; margin-top: 20px;border-top-right-radius:26px;border-bottom-right-radius:26px;" v-if="users.usersid>0&&users.userstype==1" @click="chatopen(jobs.jobusersid,jobs.jobid,jobs.jobname)">
          <div style="line-height: 18px;text-align: center;"><el-icon><ChatDotRound /></el-icon><br />沟通</div>
        </el-button>
          <br />
          <el-button  color="#dc3023" size="small" style="padding-right:20px;font-size:12px; width:52px;height:52px;font-size: 14px; margin-top: 20px;border-top-right-radius:26px;border-bottom-right-radius:26px;" v-if="users.usersid>0&&users.userstype==1"  @click="jobsq(jobs)">
          <div style="line-height: 18px;text-align: center;"><el-icon><Position /></el-icon><br />申请</div>
        </el-button>
        <br />
          <el-button  color="#dc3023" size="small" style="padding-right:20px;font-size:12px; width:52px;height:52px;font-size: 14px; margin-top: 20px;border-top-right-radius:26px;border-bottom-right-radius:26px;" v-if="users.usersid>0&&users.userstype==1" @click="scsend(jobs)">
          <div style="line-height: 18px;text-align: center;"><el-icon><Collection /></el-icon><br />收藏</div>
        </el-button>
      </el-affix>
      <!-- ------------------------------- -->
        <el-col :span="24">
            <el-row style="text-align:center;margin-bottom: 12px;margin-top: 2px;">
                <el-col :span="2" style="background-color: #dc3023;"></el-col>
                <el-col :span="4" style="color:#dc3023;font-size: 22px;"><b>招聘岗位</b></el-col>
                <el-col :span="18" style="background-color: #dc3023;color:#ffffff;text-align: right;padding-right: 20px;">WE NEED YOU</el-col>
            </el-row>
            <!--  header ------------------------------- -->
            <el-row style="text-align:left; margin-bottom: 2px;">
                <el-col :span="20" style="padding-left: 20px;">
                  <h2 style="margin-top: 2px;margin-bottom: 12px;"><b>{{ jobs.jobname }}</b>
                    <b v-if="jobs.jobsalarys.length>0" style="margin-left:30px;color: #dc3023;font-weight: normal;">¥ {{ jobs.jobsalarys }}</b>
                    <b v-else style="margin-left:30px;color: #dc3023;font-weight: normal;">¥ {{ jobs.jobsalary }}</b>
                  </h2>
                  <div style="height: 32px; line-height: 22px;">
                    <el-icon color="#ba181b"><LocationInformation /></el-icon>  {{ jobs.jobarea }}
                    <el-icon style="margin-left: 28px;"><img src="/images/gongzuojingli.png" style="width:18px; margin-right: 12px;" /></el-icon>  {{ jobs.jobexperience }}
                    <el-icon style="margin-left: 28px;"><img src="/images/jiaoyujingli.png" style="width:18px; margin-right: 12px;" /></el-icon>  {{ jobs.jobeducation }}
                    <el-icon style="margin-left: 28px;padding-top: 6px;"><img src="/images/renshushuliang.png" style="width:18px; margin-right: 12px;" /></el-icon>
                   人数：{{ jobs.jobcount }} 人
                       <el-icon style="margin-left: 28px;padding-top: 6px;"><img src="/images/xingbie.png" style="width:18px; margin-right: 12px;" /></el-icon>
                      性别：{{ jobs.jobgender }}
                    <span style="margin-left: 20px;" v-if="jobs.jobdepartment!=''&&jobs.jobdepartment!='0'">
                      <el-icon style="margin-left: 28px;"><img src="/images/bumen.png" style="width:18px; margin-right: 12px;" /></el-icon>
                     部门：{{ jobs.jobdepartment }}
                   </span>
                   <el-icon style="margin-left: 28px;"><img src="/images/nianling.png" style="width:18px; margin-right: 12px;" /></el-icon>
                      年龄：{{ jobs.jobage }}
                  </div>
                  <!-- <div v-if="jobs.jobwelfare.length>0">
                      <el-button v-for="bitem in jobs.jobwelfare.substring(0,jobs.jobwelfare.length-1).split(',')" :key="bitem" text bg size="small">{{ bitem }}</el-button>
                      <el-button  text bg size="small" v-if="jobwelfares!=''"  style="margin-left: 20px;">{{ jobs.jobwelfares }}</el-button>
                  </div> -->
                  <div style="padding-top:2px;" v-if="jobs.jobwelfare!='undefined'">
                    <span v-for="bitem in jobs.jobwelfare.substring(0,jobs.jobwelfare.length).split(',')" :key="bitem">
                      <el-button  text bg size="small" v-if="bitem!=''" style="margin-right: 12px;">{{ bitem }}</el-button>
                    </span>
                    <span v-if="jobs.jobwelfares!='undefined'&&jobs.jobwelfares!=''">
                      <el-button  text bg size="small" style="margin-right: 12px;">{{ jobs.jobwelfares }}</el-button>
                    </span>
                  </div>
                </el-col>
                <el-col :span="4">
                    <div style="text-align: right;font-size: 12px;line-height: 13px;padding-right: 20px;color:#888888;">
                        <img :src="baseurl+'user/generate?text=http://m.kmzp.com/jobitem?id='+jobs.jobid" style="width:52px;height:52px" />
                        <br />扫码查看
                    </div>
                </el-col>
            </el-row>
            <!--  header ------------------------------- -->
        </el-col>
        <el-col :span="24">
          <el-row :gutter="20" style="text-align:center;">
            <el-col :span="18">
              <!--职位描述----------------------------->
              <el-row style="text-align:center;">
            <el-col :span="24" style="text-align: left;padding-top: 0px;">
                    <h2 style="font-size:18px;margin-top: 12px;"><img src="/images/zhiweimiaoshu.png" style="width:18px; margin-right: 12px;" />职位描述</h2>
                    <el-divider style="color: #ffffff; margin-top: 2px;margin-bottom: 2px;" />
                    <div style="font-size:14px;padding-left: 32px;padding-right: 28px;margin-bottom:58px;" v-html="jobs.jobcontent"></div>
                <!--联系方式----------------------------->
                <el-divider content-position="left" style="margin-top: 12px;margin-bottom:42px;"><b>联系方式</b></el-divider>
                <el-row :gutter="2" style="text-align:center;width: 100%;text-align: left;" class="exitem">
                    <el-col :span="24">
                      <el-row :gutter="2" style="text-align:center;width: 90%;text-align: left;margin-bottom: 12px;">
                      <el-col :span="1"></el-col>
                      <el-col :span="1" style="text-align: right;">
                        <el-icon style="background-color: #333333;color:#ffffff;border-radius: 20px;padding:6px; margin-right: 12px;"><Phone /></el-icon>
                      </el-col>
                      <el-col :span="6" style="text-align: left;">
                        <span v-if="ckphone()">
                          <img :src="baseurl+'user/phone?p='+contactphone" />
                        </span>
                        <span v-else>
                          <img :src="baseurl+'user/phone?p='+contactphone" /></span>
                          <span v-if="ckphone()">
                             <el-button  color="#dc3023" size="small"  @click="jobsq(jobs)" style="margin-top: -26px;">
                             <el-icon style="margin-right:2px;"><Position /></el-icon>申请岗位查看电话</el-button>
                          </span>
                      </el-col>
                      <el-col :span="2" style="text-align: right;"><el-icon style="background-color: #333333;color:#ffffff;border-radius: 20px;padding:6px; margin-right: 12px;"><Message /></el-icon></el-col>
                      <el-col :span="6" style="text-align: left;"><b>{{contactmail}}</b></el-col>
                      <el-col :span="2" style="text-align: right;"><el-icon style="background-color: #333333;color:#ffffff;border-radius: 20px;padding:6px; margin-right: 12px;"><Service /></el-icon></el-col>
                      <el-col :span="6" style="text-align: left;"><b>{{contactname}}</b></el-col>
                      </el-row>
                  </el-col>
                </el-row>
                <el-row :gutter="2" style="text-align:center;width: 100%;text-align: left;" class="exitem">
                    <el-col :span="24">
                      <el-row :gutter="2" style="text-align:center;width: 90%;text-align: left;margin-bottom: 12px;">
                      <el-col :span="1"></el-col>
                      <el-col :span="1" style="text-align: right;"><el-icon style="background-color: #333333;color:#ffffff;border-radius: 20px;padding:6px; margin-right: 12px;"><LocationInformation /></el-icon></el-col>
                      <el-col :span="20" style="text-align: left;"><b>{{companys.companyaddress}}</b></el-col>
                      </el-row>
                      <el-row :gutter="2" style="text-align:center; margin-top: 18px;" v-if="companys.companybus!=null">
                          <el-col :span="2" style="text-align: right;"><el-icon style="background-color: #333333;color:#ffffff;border-radius: 20px;padding:6px; margin-right: 12px;"><Guide /></el-icon></el-col>
                          <el-col :span="16" style="text-align: left;"><b>{{companys.companybus}}</b></el-col>
                      </el-row>
                  </el-col>
                </el-row>
            </el-col>
              </el-row>
            <!--公司介绍----------------------------->
              <el-row style="text-align:center;">
              <el-col :span="24" style="text-align: left;">
                  <h2 style="font-size:18px;"><img src="/images/gongsijieshao.png" style="width:18px; margin-right: 12px;" />公司介绍</h2>
                  <el-divider style="margin-top: 2px;margin-bottom: 2px;" />
                  <el-row :gutter="2" style="text-align:center;width: 100%;text-align: left;" class="exitem">
                      <el-col :span="24">
                        <div style="font-size:14px;padding-left: 32px;padding-right: 28px;margin-bottom:58px;">
                          <span v-html="companys.companycontent"></span>
                        </div>
                      </el-col>
                  </el-row>
                  <el-row :gutter="2" style="text-align:center;width: 100%;text-align: left;" class="exitem">
                          <el-col :span="24">
                            <div style="font-size:14px;padding-left: 32px;padding-right: 28px;margin-bottom:58px;">
                              <iframe id="mapiframe" v-if="isrc2.length>0" :src="isrc2" :style="'padding:1px;height:318px;width:100%;min-height:308px;background-color:#cccccc;'" frameborder="0"></iframe>
                            </div>
                          </el-col>
                      </el-row>
              </el-col>
              </el-row>
              </el-col>
            <!-- ------------------------------- -->
            <el-col :span="6" style="background-color: #f8f8f8;border-top-color: #dc3023;border-top-width: 2px;border-top-style: solid;">
                <!--公司基本信息----------------------------->
                <el-row style="text-align:center;">
                  <el-col :span="24" style="text-align: left;">
                      <h2 style="font-size:18px;"><img src="/images/gongsijieshao.png" style="width:18px; margin-right: 12px;" />公司基本信息</h2>
                      <el-divider style="margin-top: 2px;" />
                      <el-row style="text-align:center;width: 100%;text-align: left;" class="exitem">
                          <el-col :span="24" style="text-align: left;">
                              <b style="font-weight: bold;">{{ companys.companyname }}</b>
                          </el-col>
                          </el-row>
                          <el-row style="text-align:center;width: 100%;text-align: left;" class="exitem">
                          <el-col :span="5" style="text-align: right;">行业：</el-col>
                          <el-col :span="19" style="text-align: left;">
                              <b>{{ companys.companyindustry }}</b>
                          </el-col>
                          </el-row>
                          <el-row style="text-align:center;width: 100%;text-align: left;" class="exitem">
                          <el-col :span="5" style="text-align: right;">规模：</el-col>
                          <el-col :span="19" style="text-align: left;">
                              <b>{{ companys.companyemployees }}</b>
                          </el-col>
                          </el-row>
                          <el-row style="text-align:center;width: 100%;text-align: left;" class="exitem">
                          <el-col :span="24" style="text-align: center;">
                            <a :href="'/companyitem?id='+companys.companyusersid+'&tt=job'" style="text-decoration:none;"><el-button color="#dc3023" size="small" style="font-size:12px;font-size: 14px; margin-top: 20px;"><el-icon style="margin-right: 8px;"><CoffeeCup /></el-icon>查看全部岗位</el-button></a>
                          </el-col>
                      </el-row>
                  </el-col>
                </el-row>
                <!--推荐岗位----------------------------->
                <el-row style="text-align:center;">
                  <el-col :span="24" style="text-align: left;">
                  <h2 style="font-size:18px;"><img src="/images/tuijiangangwei.png" style="width:18px; margin-right: 12px;" />推荐岗位</h2>
                  <el-divider style="margin-top: 2px;" />
                  <el-row :gutter="2" style="text-align:center;width: 100%;text-align: left;" >
                      <el-col :span="24" v-for="item in joblist" :key="item.jobid" style="background-color: #ffffff; margin-bottom: 6px;border-radius: 6px;">
                            <el-row class="el-row" gutter="12" style="font-size:14px;width:96%;margin-left:2%;text-align: left; line-height: 32px; color:#888888;">
                              <el-col :span="24">
                                <el-link :underline="false" :href="'/jobitem?id='+item.jobid" style="font-weight: normal;font-size: 14px;color:#333333;">{{item.jobname}}</el-link>
                              </el-col>
                            </el-row>
                            <el-row class="el-row" gutter="12" style="font-size:14px;width:96%;margin-left:2%;text-align: left; line-height: 32px; color:#888888;">
                              <el-col :span="8">
                                <div style="font-size:12px;">
                                  <span>{{ item.jobarea }}</span>
                                </div>
                              </el-col>
                              <el-col :span="16">
                                <span class="xinzi" style="margin-right: 8px;font-size: 12px;" v-if="item.jobsalarys!=''">￥{{ item.jobsalarys }}<el-divider direction="vertical" /></span>
                              <span class="xinzi" style="margin-right: 8px;font-size: 12px;" v-else>￥{{ item.jobsalary }}<el-divider direction="vertical" /></span>
                              </el-col>
                            </el-row>
                      </el-col>
                  </el-row>
                  </el-col>
                </el-row>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="24" style="text-align: left;font-size: 12px;line-height: 26px;padding-top: 6px;padding-bottom: 6px; color: #333333;background-color: #f8f8f8;">
          Copyright © 2024 开门招聘（云南）人力资源有限公司 <a href="https://beian.miit.gov.cn/" target="_blank" style="margin-left: 10px;color:#333333;"><b>滇ICP备18009977号</b></a> <span style="margin-left: 10px;">增值电信业务经营许可证：<b>滇B2-20220163</b></span>
          <a href="http://www.kmzp.com/images/rlzy.jpg" target="_blank" style="margin-left: 10px;">人力资源服务许可证：<b>盘人社许可[2022]3-32号</b></a>
          <span style="margin-left: 16px;"><a href="https://beian.mps.gov.cn/#/query/webSearch?code=53010302001462" rel="noreferrer" target="_blank" style="color: #333333;"><img src="http://www.kmzp.com/images/ga.jpeg" style="border: 0px;height: 18px; margin-bottom: -5px;margin-right: 3px;" />滇公网安备53010302001462</a></span>
          <br />
未经www.kmzp.com同意，不得转载昆明招聘网招聘信息，不得将昆明招聘网招聘信息用于其他商业用途 本站常年法律顾问团：北京大成（昆明）律师事务所
      </el-col>
    </el-row>
  </div>
  <div v-if="jobs.jobid==0" style="text-align: center; padding: 260px; font-size: 14px; line-height:42px; color: #333333;">
    <img src="/images/4043.png" style="height:72px;" />
    <br /> 数据不存在 <a href="/" style="font-size: 16px; color: red;text-decoration:none;">返回</a> 再找找看
  </div>
<!-- chat_box -------------------------------------- -->
<div :style="'position:fixed;z-index:9999;bottom:0;right:0;background-color:#ffffff;width:568px;height:528px;padding:2px;display:'+drawer">
  <div style="font-size: 12px;line-height: 22px;" @click="drawer='none'"><el-button  color="#c93756" size="small" round style="font-size:10px;margin-left: 498px;" >关闭</el-button>
  </div>
  <iframe id="iframe" :src="iframeSrc" :style="'height:'+boxheight+'px;width:100%;min-height:618px;background-color:#f0f0f0;'" frameborder="0"></iframe>
</div>
<!-- chat_box -------------------------------------- -->
  </template>
<script>

import { getuserprofile, jobsb, getjobitem, getcompanyitem, collectyonsc, applysq, departmentss } from '@/api/user'
import token from '@/utils/token'
import store from '@/store/index'
export default {
  name: 'jobitemIndex',
  components: {
  },
  props: {},
  data () {
    return {
      baseurl: store.state.baseUrl,
      drawer: 'none',
      iframeSrc: '',
      users: { usersid: 0 },
      companys: { companyid: 0 },
      jobs: { jobid: 0 },
      joblist: [],
      isrc2: '',
      lng: '102.83944512326053',
      lat: '24.886272111644136',
      loadingmain: true,
      boxheight: 518,
      departments: [],
      contactname: '',
      contactphone: '',
      contactmail: ''
    }
  },
  computed: {},
  watch: {},
  created () {
    const id = this.$route.query.id
    this.getusers()
    this.getjobs(id)
    this.getjoblist()
  },
  mounted () {},
  methods: {
    getusers () {
      getuserprofile().then(res => {
        if (res.data.resultbody != null) {
          this.users = res.data.resultbody
          if (this.users.usersstatus > 0) {
            token.settoken('utoken', '')
            this.users = { usersid: 0 }
          }
        } else {
          token.settoken('utoken', '')
        }
      })
    },
    getjobs (id) {
      getjobitem(id, 0).then(res => {
        if (res.data.status === 200) {
          this.jobs = res.data.resultbody
          this.jobs.jobcontent = this.jobs.jobcontent.replaceAll(/\r\n/ig, '<br />')
          this.jobs.jobcontent = this.jobs.jobcontent.replaceAll(/\r/ig, '<br />')
          this.jobs.jobcontent = this.jobs.jobcontent.replaceAll(/\n/ig, '<br />')
          this.getcompanys(this.jobs.jobusersid)
        }
      }).catch(res => {
        this.$message({
          message: '::>_<:: 出错了！联系一下管客服吧！' + JSON.stringify(res),
          type: 'error'
        })
      })
    },
    getcompanys (cid) {
      getcompanyitem(cid, 0, this.jobs.jobid).then(res => {
        if (res.data.status === 200) {
          this.companys = res.data.resultbody
          this.companys.companycontent = this.companys.companycontent.replaceAll(/\r\n/ig, '<br />')
          this.companys.companycontent = this.companys.companycontent.replaceAll(/\r/ig, '<br />')
          this.companys.companycontent = this.companys.companycontent.replaceAll(/\n/ig, '<br />')
          if (this.companys.companymap.split('&').length === 7) {
            const center = this.companys.companymap.split('&')[3].replaceAll(/center=/ig, '')
            if (center.split(',').length === 2) {
              this.lng = center.split(',')[0]
              this.lat = center.split(',')[1]
              window.lng = this.lng
              window.lat = this.lat
              window.incaddress = this.companys.companyaddress
              window.inccontacts = this.companys.companycontacts
              window.inctitle = this.companys.companyname
              this.isrc2 = '/map2.html'
              this.contactname = this.companys.companycontacts
              this.contactphone = this.companys.companyusersphone
              this.contactmail = this.companys.companymail
            }
          }
          this.loadingmain = false
          this.boxheight = 1
        }
        this.getdepartments()
      }).catch(res => {
        this.$message({
          message: '::>_<:: 出错了！联系一下管客服吧！' + JSON.stringify(res),
          type: 'error'
        })
      })
    },
    getjoblist () {
      jobsb(1, 1, 8, '', 'random()').then(res => {
        if (res.data.resultbody.resultlist != null) {
          this.joblist = res.data.resultbody.resultlist
        }
      })
    },
    jobsq (item) {
      const companyid = item.jobusersid
      const jobids = item.jobid
      if (companyid > 0 && jobids > 0) {
        applysq(jobids, companyid).then(res => {
          if (res.data.status === 200) {
            this.loading = false
            this.$message({
              message: '操作成功！',
              type: 'success'
            })
            this.getjobs(this.jobs.jobid)
          } else {
            this.$message({
              message: '::>_<:: ' + res.data.message + '',
              type: 'error'
            })
          }
        }).catch(res => {
          this.$message({
            message: '::>_<:: 出错了！联系一下管客服吧！',
            type: 'error'
          })
        })
      } else {
        this.$message({
          message: '::>_<:: 请选择邀请面试的岗位！',
          type: 'error'
        })
      }
    },
    scsend (item) {
      collectyonsc(item.jobid).then(res => {
        if (res.data.status === 200) {
          this.loading = false
          this.$message({
            message: '操作成功！',
            type: 'success'
          })
        } else {
          this.$message({
            message: '::>_<:: ' + res.data.message + '',
            type: 'error'
          })
        }
      }).catch(res => {
        this.$message({
          message: '::>_<:: 出错了！联系一下管客服吧！',
          type: 'error'
        })
      })
    },
    chatopen (id, jobid, jobname) {
      this.drawer = 'block'
      this.iframeSrc = '/chats?id=' + id + '&jobid=' + jobid + '&jobname=' + jobname
    },
    ckphone () {
      const phone = this.companys.companyusersphone
      let ck = false
      let ckcount = 0
      if (phone !== undefined) {
        for (let i = 0; i < phone.length; i++) {
          if (phone[i] === '*') {
            ckcount += 1
            if (ckcount > 1) {
              ck = true
              return true
            }
          }
        }
      }
      return ck
    },
    getdepartments () {
      if (this.jobs.jobdepartment.length > 1) {
        departmentss(this.jobs.jobusersid, this.jobs.jobdepartment).then(res => {
          if (res.data.resultbody.resultlist != null) {
            this.departments = res.data.resultbody.resultlist
            if (this.departments.length > 0) {
              this.contactname = this.departments[0].departmentcontacts
              this.contactmail = this.departments[0].departmentmail
              if (this.ckphone) {
                if (this.departments[0].departmentphone.length > 3) {
                  this.contactphone = this.departments[0].departmentphone.substring(0, 3) + '******'
                } else {
                  this.contactphone = this.departments[0].departmentphone
                }
              } else {
                this.contactphone = this.departments[0].departmentphone
              }
            }
          }
        })
      }
    }
  }
}
</script>

<style scoped lang="less">
.hender_a{
  height:60px;
  display: flex;
  justify-content: center;
  align-items: top;
  width:100%;
  min-width: 1180px;
  background-color: #ffffff;
  margin-top: 0px;
.henderbox{
  background-color: #ffffff;height:46px;line-height: 46px;margin-top: 8px;width:1188px;max-width: 1188px;min-width: 1180px; text-align: left;align-items: left;
  a {
    line-height: 32px;
    font-size: 14px;
    color: #333333;
    margin-left: 22px;
    text-decoration: none;
  }
}
}
.xinzi {
  color: brown;
  font-size: 14px;
  font-weight: bold;
 }
h2{margin-top: 30px;margin-bottom: 6px;}
.exitem{color: #888888;
b{color:#333333;font-weight: normal;}
}
</style>
