<template>
<el-header class="hender_a">
      <div class="henderbox">
        <el-row>
              <el-col :span="6"><img src="/images/logoa.png" style="height:45px; margin-left: 20px;" /></el-col>
              <el-col :span="16">
                <a href="/">首页</a>
                <a href="/jobs">找工作</a>
                <a href="/resumessearch">找人才</a>
                <a href="/senior">高级岗位</a>
              </el-col>
         </el-row>
      </div>
</el-header>
<div v-loading="loadingmain" :style="'height:'+ boxheight+'px;'"></div>
<div style="width: 100%;padding-top:0px;padding-bottom: 100px;min-width:1180px;margin-top: 0px;" v-if="companys.companyid>0">
  <el-row :gutter="20" style="width:100%; max-width: 1188px;margin-left: auto; margin-right: auto; background-color: #ffffff;line-height: 42px; font-size: 14px;height:42px;">
    <el-col :span="24" style="text-align: center;"></el-col>
  </el-row>
  <el-row :gutter="20" style="width:100%; max-width: 1188px;margin-left: auto; margin-right: auto; background-color: #ffffff;line-height: 30px; font-size: 14px;">
    <el-affix :offset="298" style="margin-left: 1188px; height:1px;">
      <el-button  color="#dc3023" size="small" style="padding-right:20px;font-size:12px; width:52px;height:52px;font-size: 14px; margin-top: 20px;border-top-right-radius:26px;border-bottom-right-radius:26px;" v-if="users.usersid>0" @click="chatopen(companys.companyusersid)">
        <div style="line-height: 18px;text-align: center;"><el-icon><ChatDotRound /></el-icon><br />沟通</div>
      </el-button>
    </el-affix>
    <!-- ------------------------------- -->
      <el-col :span="24">
          <el-row style="text-align:center;margin-bottom: 12px;margin-top: 12px;">
              <el-col :span="2" style="background-color: #dc3023;"></el-col>
              <el-col :span="4" style="color:#dc3023;font-size: 22px;"><b>招聘企业</b></el-col>
              <el-col :span="18" style="background-color: #dc3023;color:#ffffff;text-align: right;padding-right: 20px;">WE NEED YOU</el-col>
          </el-row>
          <!--  header ------------------------------- -->
          <el-row style="text-align:left; margin-bottom: 2px;">
              <el-col :span="20" style="padding-left: 20px;">
                <h2 style="margin-top: 16px;margin-bottom: 2px;font-size: 28px;"><b>{{ companys.companyname }}</b></h2>
              </el-col>
              <el-col :span="4">
                  <div style="text-align: right;font-size: 12px;line-height: 13px;padding-right: 20px;color:#888888;">
                    <img :src="baseurl+'user/generate?text=http://m.kmzp.com/companyitem?id='+companys.companyusersid" style="width:52px;height:52px" />
                    <br />扫码查看
                  </div>
              </el-col>
          </el-row>
           <!--  header ------------------------------- -->
      </el-col>
      <el-col :span="24">
        <el-row :gutter="20" style="text-align:center;">
            <el-col :span="18">
              <el-tabs v-model="activeName" type="card" style="margin-top: 20px;" @tab-click="handleClick">
                <el-tab-pane label="公司介绍" name="t1" class="retainlist">
                  <!--公司介绍----------------------------->
                  <el-row style="text-align:center;">
                    <el-col :span="1"></el-col>
                  <el-col :span="22" style="text-align: left;">
                      <h2 style="font-size:18px;"><img src="/images/gongsijieshao.png" style="width:18px; margin-right: 12px;" />公司介绍</h2>
                      <el-divider style="margin-top: 2px;" />
                      <el-row :gutter="2" style="text-align:center;width: 100%;text-align: left;" class="exitem">
                          <el-col :span="24">
                            <div style="font-size:14px;text-indent:2em;padding-left: 32px;padding-right: 28px;margin-bottom:58px;color: #333333;">
                              <span v-html="companys.companycontent"></span>
                            </div>
                          </el-col>
                      </el-row>
                      <el-row :gutter="2" style="text-align:center;width: 100%;text-align: left;" class="exitem" v-if="companys.companymap!=''">
                          <el-col :span="24">
                            <div style="font-size:14px;text-indent:2em;padding-left: 32px;padding-right: 28px;margin-bottom:58px;">
                              <iframe id="mapiframe" v-if="isrc2.length>0" :src="isrc2" :style="'padding:1px;height:318px;width:100%;min-height:308px;background-color:#cccccc;'" frameborder="0"></iframe>
                            </div>
                          </el-col>
                      </el-row>
                        <h2 style="font-size:18px;"><el-icon style="width:18px; margin-right: 12px; color: #dc3023;"><Phone /></el-icon> 联系方式</h2>
                      <el-divider style="margin-top: 2px;" />
                    <el-row :gutter="2" style="text-align:center;width: 100%;text-align: left;margin-top: 52px;" class="exitem">
                        <el-col :span="24">
                          <el-row :gutter="2" style="text-align:center;">
                          <el-col :span="2" style="text-align: right;"><el-icon style="background-color: #333333;color:#ffffff;border-radius: 20px;padding:6px; margin-right: 12px;"><Phone /></el-icon></el-col>
                          <el-col :span="6" style="text-align: left;">
                            <img :src="baseurl+'user/phone?p='+companys.companyusersphone" v-if="ckphone()" />
                            <img :src="baseurl+'user/phone?p='+companys.companyusersphone" v-else />
                            <span v-if="ckphone()">
                            <el-button  color="#dc3023" size="small"  @click="gologin()" style="margin-top: -26px;">
                             <el-icon style="margin-right:2px;"><User /></el-icon>求职用户登陆查看电话</el-button>
                            </span>
                          </el-col>
                          <el-col :span="2" style="text-align: right;"><el-icon style="background-color: #333333;color:#ffffff;border-radius: 20px;padding:6px; margin-right: 12px;"><Message /></el-icon></el-col>
                          <el-col :span="6" style="text-align: left;"><b>{{companys.companymail}}</b></el-col>
                          <el-col :span="2" style="text-align: right;"><el-icon style="background-color: #333333;color:#ffffff;border-radius: 20px;padding:6px; margin-right: 12px;"><Service /></el-icon></el-col>
                          <el-col :span="6" style="text-align: left;"><b>{{companys.companycontacts}}</b></el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                    <el-row :gutter="2" style="text-align:center;width: 100%;text-align: left;margin-bottom: 32px;margin-top: 22px;" class="exitem">
                        <el-col :span="24">
                          <el-row :gutter="2" style="text-align:center;">
                            <el-col :span="2" style="text-align: right;"><el-icon style="background-color: #333333;color:#ffffff;border-radius: 20px;padding:6px; margin-right: 12px;"><LocationFilled /></el-icon></el-col>
                            <el-col :span="16" style="text-align: left;"><b>{{companys.companyaddress}}</b></el-col>
                          </el-row>
                          <el-row :gutter="2" style="text-align:center;margin-top: 18px;" v-if="companys.companybus!=''">
                            <el-col :span="2" style="text-align: right;"><el-icon style="background-color: #333333;color:#ffffff;border-radius: 20px;padding:6px; margin-right: 12px;"><Guide /></el-icon></el-col>
                            <el-col :span="16" style="text-align: left;"><b>{{companys.companybus}}</b></el-col>
                          </el-row>
                      </el-col>
                    </el-row>
                  </el-col>
                  </el-row>
                </el-tab-pane>
                <el-tab-pane label="招聘岗位" name="t2" class="listbox">
                  <!--招聘岗位----------------------------->
                  <el-row style="text-align:center;margin-top: 30px;">
                    <el-col :span="1"></el-col>
                  <el-col :span="22" style="text-align: left;">
                    <el-row class="el-row" gutter="12" v-for="item in joblist" :key="item.jobid" style="width:100%;text-align: left;">
                  <el-col :span="24">
                  <div><el-link class="el-link" :underline="false" :href="'/jobitem?id='+item.jobid" target="_blank">{{item.jobname}}</el-link>
                    <!-- <span style="margin-left: 28px;">{{ item.jobdates.replaceAll(/T/ig," ").replaceAll(/Z/ig," ") }}</span> -->
                  </div>
                  <div>
                    <span class="xinzi" v-if="item.jobsalarys!=''">￥{{ item.jobsalarys }}<el-divider direction="vertical" /></span>
                    <span class="xinzi" v-else>￥{{ item.jobsalary }}<el-divider direction="vertical" /></span>
                    <el-divider direction="vertical" />
                    <span>经验:{{ item.jobexperience }}</span>
                    <el-divider direction="vertical" />
                    <span>{{ item.jobeducation }}</span>
                    <el-divider direction="vertical" />
                    <span>{{ item.jobarea }}</span><br />
                    <span>{{ item.jobcategory }}{{ item.jobcategorys }}</span>
                  </div>
                  <div style="padding-top:8px;" v-if="item.jobwelfare!='undefined'">
                    <span v-for="bitem in item.jobwelfare.substring(0,item.jobwelfare.length).split(',')" :key="bitem">
                      <el-button  text bg size="small" v-if="bitem!=''" style="margin-right: 12px;">{{ bitem }}</el-button>
                    </span>
          </div>
                  </el-col>
                  <el-col :span="24">
                  <div style="text-align: right;margin-top: 8px; ">
                      <el-button  color="#333333" size="small" round style="font-size:12px;" @click="jobsq(item)"><el-icon style="margin-right:2px;"><Position /></el-icon>申请</el-button>
                      <el-button  color="#333333" size="small" round style="font-size:12px;" @click="scsend(item)"><el-icon style="margin-right:2px;"><Collection /></el-icon>收藏</el-button>
                      <el-button  color="#333333" size="small" round style="font-size:12px;"><el-icon><ChatDotRound /></el-icon>沟通</el-button>
                    </div>
                  </el-col>
                  <el-divider />
                  </el-row>
                <!-- pagers -------------------------------------------- -->
                <div style="text-align: right;">
                  <el-pagination style="float: right;" small :current-page="currentpage" background :page-size="pagesize" :page-sizes="[10, 20, 30, 40]" :pager-count="5" layout="prev, pager, next" @current-change="pagechange" :total="total"/>
                </div>
                <!-- pagers -------------------------------------------- -->
                  </el-col>
                  </el-row>
                </el-tab-pane>
                <el-tab-pane label="企业展示" name="t3" class="listbox">
                  <!--招聘岗位----------------------------->
                  <el-row style="text-align:center;margin-top: 30px;">
                    <el-col :span="1"></el-col>
                    <el-col :span="22" style="text-align: left;">
                      <el-divider content-position="left" style="margin-top: 12px;margin-bottom:42px;margin-top: 42px;"><b>企业展示</b></el-divider>

                        <el-row :gutter="2" style="text-align:center;width: 100%;text-align: left;margin-bottom: 52px;" class="exitem">
                          <el-col v-for="item in piclist" :key="item.index" :span="6" >
                            <el-card :body-style="{ padding: '0px' }" style="margin-bottom: 32px; margin-right: 32px;border-radius: 18px; height:98px;" shadow="hover">
                                <el-image :src="baseurl+'static/incpic/'+companys.companyusersid+'/inc/'+item" class="image" style="height:100%;width:100%;" @click="picshow(item)" />
                            </el-card>
                          </el-col>
                        </el-row>

                    </el-col>
                  </el-row>
                </el-tab-pane>
              </el-tabs>
            </el-col>
            <el-col :span="6" style="background-color: #f8f8f8;border-top-color: #dc3023;border-top-width: 2px;border-top-style: solid;">
              <!--公司基本信息----------------------------->
              <el-row style="text-align:center;">
                <el-col :span="24" style="text-align: left;">
                    <h2 style="font-size:18px;"><img src="/images/gongsijieshao.png" style="width:18px; margin-right: 12px;" />公司基本信息</h2>
                    <el-divider style="margin-top: 2px;" />
                        <el-row style="text-align:center;width: 100%;text-align: left;" class="exitem">
                        <el-col :span="5" style="text-align: right;">性质：</el-col>
                        <el-col :span="19" style="text-align: left;">
                            <b>{{ companys.companytype }}</b>
                        </el-col>
                        </el-row>
                        <el-row style="text-align:center;width: 100%;text-align: left;" class="exitem">
                        <el-col :span="5" style="text-align: right;">行业：</el-col>
                        <el-col :span="19" style="text-align: left;">
                            <b>{{ companys.companyindustry }}</b>
                        </el-col>
                        </el-row>
                        <el-row style="text-align:center;width: 100%;text-align: left;" class="exitem">
                        <el-col :span="5" style="text-align: right;">地区：</el-col>
                        <el-col :span="19" style="text-align: left;">
                            <b>{{ companys.companyarea }}</b>
                        </el-col>
                      </el-row>
                        <el-row style="text-align:center;width: 100%;text-align: left;" class="exitem">
                        <el-col :span="5" style="text-align: right;">规模：</el-col>
                        <el-col :span="19" style="text-align: left;">
                            <b>{{ companys.companyemployees }}</b>
                        </el-col>
                      </el-row>
                        <el-row style="text-align:center;width: 100%;text-align: left;" class="exitem">
                        <el-col :span="8" style="text-align: right;">注册资金：</el-col>
                        <el-col :span="16" style="text-align: left;">
                            <b>{{ companys.companyregisteredcapital }}</b>
                        </el-col>
                        </el-row>
                        <el-row style="text-align:center;width: 100%;text-align: left;" class="exitem">
                        <el-col :span="8" style="text-align: right;">注册时间：</el-col>
                        <el-col :span="16" style="text-align: left;">
                            <b>{{ companys.companybirthday }}</b>
                        </el-col>
                        </el-row>
                        <el-row style="text-align:center;width: 100%;text-align: left;" class="exitem">
                        <el-col :span="24" style="text-align: center;">
                          <el-button color="#dc3023" size="small" style="font-size:12px;font-size: 14px; margin-top: 20px;" @click="activeName='t2'"><el-icon style="margin-right: 8px;"><CoffeeCup /></el-icon>查看全部岗位</el-button>
                        </el-col>
                    </el-row>
                </el-col>
              </el-row>
              <!--最新岗位----------------------------->
              <el-row style="text-align:center;">
                <el-col :span="24" style="text-align: left;">
                <h2 style="font-size:18px;"><img src="/images/tuijiangangwei.png" style="width:18px; margin-right: 12px;" />推荐岗位</h2>
                <el-divider style="margin-top: 2px;" />
                <el-row :gutter="2" style="text-align:center;width: 100%;text-align: left;" >
                    <el-col :span="24" v-for="item in joblist" :key="item.jobid" style="background-color: #ffffff; margin-bottom: 6px;border-radius: 6px;">
                          <el-row class="el-row" gutter="12" style="font-size:14px;width:96%;margin-left:2%;text-align: left; line-height: 32px; color:#888888;">
                            <el-col :span="24">
                              <el-link :underline="false" :href="'/jobitem?id='+item.jobid" style="font-weight: normal;font-size: 14px;color:#333333;">{{item.jobname}}</el-link>
                            </el-col>
                          </el-row>
                          <el-row class="el-row" gutter="12" style="font-size:14px;width:96%;margin-left:2%;text-align: left; line-height: 32px; color:#888888;">
                            <el-col :span="8">
                              <div style="font-size:12px;">
                                <span>{{ item.jobarea }}</span>
                              </div>
                            </el-col>
                            <el-col :span="16">
                              <span class="xinzi" style="margin-right: 8px;font-size: 12px;" v-if="item.jobsalarys!=''">￥{{ item.jobsalarys }}<el-divider direction="vertical" /></span>
                              <span class="xinzi" style="margin-right: 8px;font-size: 12px;" v-else>￥{{ item.jobsalary }}<el-divider direction="vertical" /></span>
                            </el-col>
                          </el-row>
                    </el-col>
                 </el-row>
                </el-col>
              </el-row>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="24" style="text-align: left;font-size: 12px;line-height: 26px;padding-top: 6px;padding-bottom: 6px; color: #333333;background-color: #f8f8f8;">
        Copyright © 2024 开门招聘（云南）人力资源有限公司 <a href="https://beian.miit.gov.cn/" target="_blank" style="margin-left: 10px;color:#333333;"><b>滇ICP备18009977号</b></a> <span style="margin-left: 10px;">增值电信业务经营许可证：<b>滇B2-20220163</b></span>
          <a href="http://www.kmzp.com/images/rlzy.jpg" target="_blank" style="margin-left: 10px;">人力资源服务许可证：<b>盘人社许可[2022]3-32号</b></a>
          <span style="margin-left: 16px;"><a href="https://beian.mps.gov.cn/#/query/webSearch?code=53010302001462" rel="noreferrer" target="_blank" style="color: #333333;"><img src="http://www.kmzp.com/images/ga.jpeg" style="border: 0px;height: 18px; margin-bottom: -5px;margin-right: 3px;" />滇公网安备53010302001462</a></span>
          <br />
未经www.kmzp.com同意，不得转载昆明招聘网招聘信息，不得将昆明招聘网招聘信息用于其他商业用途 本站常年法律顾问团：北京大成（昆明）律师事务所
      </el-col>
  </el-row>
</div>
  <el-dialog v-model="picdialogFormVisible" title="企业展示">
<div style="width:100%;padding-bottom: 8px;">
    <el-button color="#c32136" size="small" @click="previouspic">上一张</el-button>
    <el-button color="#c32136" size="small" @click="nextpic" style="float:right;">下一张</el-button>
</div>
    <el-image :src="baseurl+'static/incpic/'+companys.companyusersid+'/inc/'+picitem" class="image" style="height:100%;width:100%;" />
  </el-dialog>
<!------------------------------------------------------>
<div v-if="companys.companyid==0" style="text-align: center; padding: 260px; font-size: 14px; line-height:42px; color: #333333;">
    <img src="/images/4043.png" style="height:72px;" />
    <br /> 数据不存在 <a href="/" style="font-size: 16px; color: red;text-decoration:none;">返回</a> 再找找看
</div>
<!-- chat_box -------------------------------------- -->
<div :style="'position:fixed;z-index:9999;bottom:0;right:0;background-color:#ffffff;width:568px;height:528px;padding:2px;display:'+drawer">
  <div style="font-size: 12px;line-height: 22px;" @click="drawer='none'"><el-button  color="#c93756" size="small" round style="font-size:10px;margin-left: 498px;" >关闭</el-button>
  </div>
  <iframe id="iframe" :src="iframeSrc" :style="'height:'+boxheight+'px;width:100%;min-height:618px;background-color:#f0f0f0;'" frameborder="0"></iframe>
</div>
<!-- chat_box -------------------------------------- -->
  </template>
<script>

import store from '@/store/index'
import { getuserprofile, jobsc, getcompanyitem, applysq, collectyonsc, companypics } from '@/api/user'
import token from '@/utils/token'
export default {
  name: 'companyitemIndex',
  components: {
  },
  props: {},
  data () {
    return {
      drawer: 'none',
      iframeSrc: '',
      picdialogFormVisible: false,
      picitem: '',
      baseurl: store.state.baseUrl,
      activeName: 't1',
      users: { usersid: 0 },
      companys: { companyid: 0 },
      jobs: { jobid: 0 },
      joblist: [],
      pagesize: 5,
      total: 0,
      currentpage: 1,
      piclist: [],
      isrc2: '',
      lng: '102.83944512326053',
      lat: '24.886272111644136',
      inctitle: '',
      inccontacts: '',
      incaddress: '',
      loadingmain: true,
      boxheight: 518
    }
  },
  computed: {},
  watch: {},
  created () {
    this.boxheight = document.documentElement.clientHeight
    const id = this.$route.query.id
    const tt = this.$route.query.tt
    if (tt === 'job') {
      this.activeName = 't2'
    }
    this.getusers()
    window.uuurl = store.state.baseUrl
    window.lng = this.lng
    window.lat = this.lat
    window.incaddress = this.incaddress
    window.inccontacts = this.inccontacts
    window.inctitle = this.inctitle
    this.getcompanys(id)
  },
  mounted () {},
  methods: {
    getusers () {
      getuserprofile().then(res => {
        if (res.data.resultbody != null) {
          this.users = res.data.resultbody
          if (this.users.usersstatus > 0) {
            token.settoken('utoken', '')
            this.users = { usersid: 0 }
          }
        } else {
          token.settoken('utoken', '')
        }
      })
    },
    getcompanys (cid) {
      getcompanyitem(cid, 0, 0).then(res => {
        if (res.data.status === 200) {
          this.companys = res.data.resultbody
          this.getjoblist()
          this.getpic()
          this.companys.companycontent = this.companys.companycontent.replaceAll(/\r\n/ig, '<br />')
          this.companys.companycontent = this.companys.companycontent.replaceAll(/\r/ig, '<br />')
          this.companys.companycontent = this.companys.companycontent.replaceAll(/\n/ig, '<br />')
          if (this.companys.companymap.split('&').length === 7) {
            const center = this.companys.companymap.split('&')[3].replaceAll(/center=/ig, '')
            if (center.split(',').length === 2) {
              this.lng = center.split(',')[0]
              this.lat = center.split(',')[1]
              window.lng = this.lng
              window.lat = this.lat
              window.incaddress = this.companys.companyaddress
              window.inccontacts = this.companys.companycontacts
              window.inctitle = this.companys.companyname
              this.isrc2 = '/map2.html'
            }
          }
          this.loadingmain = false
          this.boxheight = 1
        }
      }).catch(res => {
        this.$message({
          message: '::>_<:: 出错了！联系一下管客服吧！' + JSON.stringify(res),
          type: 'error'
        })
      })
    },
    getjoblist () {
      // jobsc(1, this.currentpage, this.pagesize, '', '', this.companys.companyusersid).then(res => {
      jobsc(1, this.currentpage, this.pagesize, '', '', this.companys.companyusersid).then(res => {
        if (res.data.resultbody.resultlist != null) {
          this.joblist = res.data.resultbody.resultlist
          if (this.currentpage === 1) {
            this.total = res.data.resultbody.total
          }
        }
      })
    },
    pagechange (p) {
      if (this.page !== p) {
        this.currentpage = p
        this.getjoblist()
      }
    },
    jobsq (item) {
      const companyid = item.jobusersid
      const jobids = item.jobid
      if (companyid > 0 && jobids > 0) {
        applysq(jobids, companyid).then(res => {
          if (res.data.status === 200) {
            this.loading = false
            this.$message({
              message: '操作成功！',
              type: 'success'
            })
          } else {
            this.$message({
              message: '::>_<:: ' + res.data.message + '',
              type: 'error'
            })
          }
        }).catch(res => {
          this.$message({
            message: '::>_<:: 出错了！联系一下管客服吧！',
            type: 'error'
          })
        })
      } else {
        this.$message({
          message: '::>_<:: 请选择邀请面试的岗位！',
          type: 'error'
        })
      }
    },
    scsend (item) {
      collectyonsc(item.jobid).then(res => {
        if (res.data.status === 200) {
          this.loading = false
          this.$message({
            message: '操作成功！',
            type: 'success'
          })
        } else {
          this.$message({
            message: '::>_<:: ' + res.data.message + '',
            type: 'error'
          })
        }
      }).catch(res => {
        this.$message({
          message: '::>_<:: 出错了！联系一下管客服吧！',
          type: 'error'
        })
      })
    },
    getpic () {
      companypics(this.companys.companyusersid).then(res => {
        if (res.data.status === 200) {
          if (JSON.stringify(res.data.resultbody) != null) {
            this.piclist = res.data.resultbody
          }
        } else {
          this.$message({
            message: '::>_<:: 出错了！联系一下管客服吧！(' + res.data.message + ')',
            type: 'error'
          })
        }
      }).catch(res => {
        this.$message({
          message: '::>_<:: 出错了！联系一下管客服吧！',
          type: 'error'
        })
      })
    },
    picshow (item) {
      this.picdialogFormVisible = true
      this.picitem = item
    },
    previouspic () {
      const pindex = this.piclist.indexOf(this.picitem)
      if (pindex > 0) {
        this.picitem = this.piclist[pindex - 1]
      } else {
        this.picitem = this.piclist[this.piclist.length - 1]
      }
    },
    nextpic () {
      const pindex = this.piclist.indexOf(this.picitem)
      if (pindex < this.piclist.length - 1) {
        this.picitem = this.piclist[pindex + 1]
      } else {
        this.picitem = this.piclist[0]
      }
    },
    chatopen (id) {
      this.drawer = true
      this.iframeSrc = '/chats?id=' + id
    },
    handleClick (v) {
      this.isrc2 = '/map2.html' + '?t=' + new Date().getSeconds()
    },
    ckphone () {
      const phone = this.companys.companyusersphone
      let ck = false
      let ckcount = 0
      if (phone !== undefined) {
        for (let i = 0; i < phone.length; i++) {
          if (phone[i] === '*') {
            ckcount += 1
            if (ckcount > 1) {
              ck = true
              return true
            }
          }
        }
      }
      return ck
    },
    gologin () {
      this.$router.push('/login')
    }
  }
}
</script>

<style scoped lang="less">
.hender_a{
  height:60px;
  display: flex;
  justify-content: center;
  align-items: top;
  width:100%;
  min-width: 1288px;
  background-color: #ffffff;
  margin-top: 0px;
.henderbox{
  background-color: #ffffff;height:46px;line-height: 46px;margin-top: 8px;width:1188px;max-width: 1188px;min-width: 98px; text-align: left;align-items: left;
  a {
    line-height: 32px;
    font-size: 14px;
    color: #333333;
    margin-left: 22px;
    text-decoration: none;
  }
}
}
.xinzi {
  color: brown;
  font-size: 14px;
  font-weight: bold;
 }
h2{margin-top: 30px;margin-bottom: 6px;}
.exitem{color: #888888;
b{color:#333333;font-weight: normal;}
}

.listbox
{
  font-size: 14px;line-height:26px;
  .idbox{margin-right: 12px;}
  .el-row{ margin-bottom: 8px;padding-left: 12px; padding-right: 12px;}
  .el-divider {
    margin-top:12px;
    margin-bottom: 12px;
  }
.el-link{font-size: 16px;font-weight: bold; color: #333333;}
.el-link2{font-size: 14px;font-weight:normal;color:#666666;}
.el-divider {
    margin-top:12px;
    margin-bottom: 12px;
  }
  .xinzi {
  color: brown;
  font-size: 14px;
  font-weight: bold;
 }
}
</style>
